import { useStaticQuery, graphql } from 'gatsby'
// Types
import { GatsbyImageBasicProps, ImageBasicProps } from '../../../types/images'
import { ExternalLinkProps, PageInfoLinkProps } from '../../../types/pages'
import { SocialProps } from '../../../types/items'

interface FooterQuery {
  site: {
    globalSeo: {
      siteName: string
    }
  }
  footer: {
    footerLogo: ImageBasicProps
    socials: SocialProps[]
    footerCertificationsLabel: string
    footerCertificationLogos: (GatsbyImageBasicProps & { customData: { [key: string]: string } })[]
    footerNavigation: {
      id: string
      label: string
      items: (PageInfoLinkProps | ExternalLinkProps)[]
    }[]
    legals: PageInfoLinkProps[]
    copyright: string
  }
}

export const footerQuery = (): FooterQuery => {
  return useStaticQuery<FooterQuery>(graphql`
    query {
      site: datoCmsSite {
        ...siteNameFragment
      }
      footer: datoCmsGlobal {
        footerLogo {
          alt
          url
          width
          height
        }
        socials {
          ...socialFragment
        }
        footerCertificationsLabel
        footerCertificationLogos {
          alt
          title
          gatsbyImageData(layout: CONSTRAINED, width: 100, placeholder: NONE)
          customData
        }
        footerNavigation {
          id
          label
          items {
            ... on DatoCmsPageInfo {
              ...pageInfoLinkFragment
            }
            ... on DatoCmsExternalLink {
              ...externalLinkFragment
            }
          }
        }
        legals {
          ...pageInfoLinkFragment
        }
        copyright
      }
    }
  `)
}
