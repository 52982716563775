import React from 'react'
// Types
import { SubMenuProps, isSubMenuItemGroup, isSubMenuItemSimpleGroup } from '../../../../types/menus'
// Components
import SubMenuItemGroup from './SubMenuItemGroup'
import SubMenuItemGroupSimple from './SubMenuItemGroupSimple'

const SubMenu: React.FC<SubMenuProps> = ({ subMenuContent }) => {
  return (
    <>
      {subMenuContent.map((sm) => {
        if (isSubMenuItemGroup(sm)) return <SubMenuItemGroup key={sm.id} {...sm} />
        if (isSubMenuItemSimpleGroup(sm)) return <SubMenuItemGroupSimple key={sm.id} {...sm} />
      })}
    </>
  )
}

export default SubMenu
