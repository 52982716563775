import React from 'react'
//Types
import { ExternalLinkProps, PageInfoLinkProps, isInternalLink } from '../../types/pages'
import { IModal } from '../../types/modal'

import { GatsbyLink } from './Link.styled'

interface LinkProps {
  href?: string
  link?: PageInfoLinkProps | ExternalLinkProps | IModal
  fluid?: boolean
  target?: string
  rel?: string
}

function isIModal(props: PageInfoLinkProps | ExternalLinkProps | IModal): props is IModal {
  return (props as IModal).modalId !== undefined
}

const Link: React.FC<LinkProps & React.StyleHTMLAttributes<any>> = (props) => {
  const { link, href, children, style, fluid, ...otherProps } = props
  if (link) {
    if (isIModal(link)) {
      return (
        <GatsbyLink
          to={`?modalId=${link.modalId}`}
          style={{ display: fluid ? 'block' : undefined, ...style }}
          {...otherProps}
        >
          {children}
        </GatsbyLink>
      )
    } else if (isInternalLink(link)) {
      return (
        <GatsbyLink
          to={link.slug === '/' ? '/' : `/${link.slug}`}
          style={{ display: fluid ? 'block' : undefined, ...style }}
          {...otherProps}
        >
          {children}
        </GatsbyLink>
      )
    } else {
      return (
        <a
          href={link.url}
          target={link.openNewTab ? '_blank' : undefined}
          rel={link.openNewTab ? 'noreferrer noopener' : undefined}
          style={{ display: fluid ? 'block' : undefined, ...style }}
        >
          {children}
        </a>
      )
    }
  } else if (href) {
    return (
      <a href={href} style={{ display: fluid ? 'block' : undefined, ...style }} {...otherProps}>
        {children}
      </a>
    )
  } else {
    return <>{children}</>
  }
}

export default Link
