import { MarginProps, TypographyProps, ColorProps } from 'styled-system'

import { StyledHeading } from './Heading.styled'

export type HeadingProps = TypographyProps &
  ColorProps &
  MarginProps & {
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  }

StyledHeading.defaultProps = {
  as: 'h1',
}

export default StyledHeading
