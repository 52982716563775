import * as React from 'react'
import theme from '../../../theme'
function Twitter(props: React.SVGProps<SVGSVGElement & { color?: string }>): JSX.Element {
  const { color, style, ...otherProps } = props
  return (
    <svg
      width={18}
      height={15}
      viewBox='0 0 18 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 1.775a7.23 7.23 0 01-2.12.596A3.788 3.788 0 0017.503.277a7.331 7.331 0 01-2.347.92A3.64 3.64 0 0012.461 0C10.422 0 8.77 1.696 8.77 3.787c0 .297.032.586.095.863-3.068-.158-5.789-1.666-7.61-3.958-.318.559-.5 1.21-.5 1.904 0 1.315.653 2.474 1.643 3.153a3.622 3.622 0 01-1.673-.476v.048c0 1.835 1.274 3.366 2.962 3.714-.31.086-.636.133-.973.133-.238 0-.47-.024-.695-.07.47 1.505 1.833 2.6 3.448 2.63A7.29 7.29 0 010 13.297 10.247 10.247 0 005.66 15c6.793 0 10.506-5.772 10.506-10.778l-.013-.49A7.481 7.481 0 0018 1.775z'
        fill={color ? theme.colors[color] || color : 'currentColor'}
      />
    </svg>
  )
}
export default Twitter
