import React, { useRef, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
// Types
import { BannerProps } from '../../../../types/global'
// Hooks
import { useWindowSize } from '../../../../hooks'
// Components
import { Link } from 'gatsby'
import { Flex, Box, Text, Icons } from '../../../../atoms'
import { BannerWrapper, LinkWrapper, LinkArrow } from './AnnouncementBanner.styled'

type AnnouncementBannerProps = BannerProps

const AnnouncementBanner: React.FC<AnnouncementBannerProps> = ({
  identifier,
  content,
  link,
  linkText,
  openNewTab,
  backgroundColor,
}) => {
  const bannerRef = useRef<HTMLDivElement | null>(null)
  const [cookies, setCookie] = useCookies([identifier])
  const [bannerVisibile, setBannerVisibility] = useState(false)
  const { width } = useWindowSize()

  useEffect(() => {
    if (cookies && !cookies[identifier]) {
      setBannerVisibility(true)
    } else {
      setBannerVisibility(false)
    }
  }, [cookies])

  useEffect(() => {
    if (bannerRef.current) {
      document.documentElement.style.setProperty('--banner-height', `${bannerRef.current.offsetHeight}px`)
    }
  }, [bannerRef, width])

  const _handleCloseBanner = (bannerId: string): void => {
    setCookie(bannerId, true, { path: '/', secure: true })
    setBannerVisibility(false)
    document.documentElement.style.setProperty('--banner-height', '0px')
  }

  if (bannerVisibile) {
    return (
      <BannerWrapper ref={bannerRef}>
        <Flex px={4} py={4} bg={backgroundColor?.hex || 'purple'}>
          <Flex justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'} flex={'1'} pr={2} pl={4}>
            <Text fontSize={1} lineHeight={1.2} fontWeight={'semi'} color={'white'} textAlign={'center'}>
              {content}
            </Text>

            {!!link && !!linkText && (
              <LinkWrapper display={'inline-block'} mx={3}>
                {link.startsWith('/') ? (
                  <Link to={link} style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Text
                      as={'span'}
                      fontSize={1}
                      lineHeight={1.2}
                      fontWeight={'semi'}
                      color={'white'}
                      textDecoration={'underline'}
                    >
                      {linkText}
                    </Text>
                    <LinkArrow>
                      <Icons.ArrowRight width={12} height={12} color={'white'} />
                    </LinkArrow>
                  </Link>
                ) : (
                  <a
                    href={link}
                    rel={'noreferrer noopener'}
                    target={openNewTab ? '_blank' : undefined}
                    style={{ display: 'inline-flex', alignItems: 'center' }}
                  >
                    <Text
                      as={'span'}
                      fontSize={1}
                      lineHeight={1.2}
                      fontWeight={'semi'}
                      color={'white'}
                      textDecoration={'underline'}
                    >
                      {linkText}
                    </Text>
                    <LinkArrow>
                      <Icons.ArrowRight width={12} height={12} color={'white'} />
                    </LinkArrow>
                  </a>
                )}
              </LinkWrapper>
            )}
          </Flex>

          <Box flex={'0 0 16px'} clickable onClick={() => _handleCloseBanner(identifier)} color={'white'}>
            <Icons.Close />
          </Box>
        </Flex>
      </BannerWrapper>
    )
  }

  return null
}

export default AnnouncementBanner
