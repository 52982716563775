import React from 'react'
import styled, { css } from 'styled-components'
import { Flex, FlexProps, Box, BoxProps, Icons } from '../../../../atoms'

export const MenuMobileNavigationWrapper: React.FC<FlexProps> = styled(Flex)<FlexProps>`
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 24px;
    left: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
  }
`
export const MenuMobileButtonWrapper: React.FC<BoxProps> = styled(Box)<BoxProps>`
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 24px;
    left: 0;
    bottom: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
  }
`

export const MenuMobileItemChevron = styled(Icons.ChevronDown)<
  React.SVGProps<SVGSVGElement> & { color?: string } & { open?: boolean }
>`
  path {
    stroke-width: 2.4;
  }

  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}
`
