import React from 'react'
import { useTheme } from 'styled-components'

function Burger(props: React.SVGProps<SVGSVGElement> & { color?: string }): JSX.Element {
  const { color, style, ...otherProps } = props
  const theme = useTheme()
  return (
    <svg
      width={20}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <rect width={20} height={2} rx={1} fill={color ? theme.colors[color] || color : 'currentColor'} />
      <rect y={7} width={20} height={2} rx={1} fill={color ? theme.colors[color] || color : 'currentColor'} />
      <rect y={14} width={20} height={2} rx={1} fill={color ? theme.colors[color] || color : 'currentColor'} />
    </svg>
  )
}

export default Burger
