import React from 'react'
import Helmet from 'react-helmet'
import { seoQuery } from './Seo.utils'

interface SEOProps {
  readonly content?: {
    title: string
    description: string
    twitterCard: string
    image: {
      url: string
      width?: number
      height?: number
    }
  }
  readonly SDSchema?: {
    pageType?: string
    headline?: string
    image?: Array<string> | string
  }
  readonly lang?: string
  readonly slug: string
  readonly publication: {
    firstPublishedAt?: Date
    publishedAt: Date
    updatedAt: Date
  }
}

function Seo({ content, lang, publication, slug, SDSchema = {} }: SEOProps): JSX.Element {
  // Query Dato site infos
  const { datoCmsSite, site } = seoQuery()

  // Get meta from page, or get global fallback
  const metaTitle = content?.title || datoCmsSite.globalSeo.fallbackSeo.title
  const metaDescription = content?.description || datoCmsSite.globalSeo.fallbackSeo.description
  const metaImage = content?.image || datoCmsSite.globalSeo.fallbackSeo.image
  const metaTwitterCard = content?.twitterCard || datoCmsSite.globalSeo.fallbackSeo.twitterCard

  // Structured Data
  const SDataDefault = {
    '@context': 'https://schema.org/',
    name: metaTitle,
    inLanguage: lang,
    description: metaDescription,
    url: `${site.siteMetadata.siteUrl}/${slug ? slug : ''}`,
    copyrightYear: () => {
      const d = new Date()
      return d.getFullYear()
    },
    copyrightHolder: {
      '@type': 'Organization',
      name: datoCmsSite.globalSeo.siteName,
    },
    author: {
      '@type': 'Organization',
      name: datoCmsSite.globalSeo.siteName,
    },
    creator: {
      '@type': 'Organization',
      name: datoCmsSite.globalSeo.siteName,
    },
    publisher: {
      '@type': 'Organization',
      name: datoCmsSite.globalSeo.siteName,
    },
    datePublished: publication?.firstPublishedAt || publication?.publishedAt,
    dateModified: publication?.updatedAt,
  }

  const SDImageDefault = {
    '@type': 'ImageObject',
    url: metaImage?.url,
  }

  const SDataSchema = {
    '@type': SDSchema.pageType ? SDSchema.pageType : 'WebPage',
    image: SDSchema.image ? SDSchema.image : SDImageDefault,
    headline: SDSchema.headline ? SDSchema.headline : metaTitle,
    ...SDataDefault,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={'%s'}
      meta={[
        {
          name: 'application-name',
          content: datoCmsSite.globalSeo.siteName,
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'og:site_name',
          content: datoCmsSite.globalSeo.siteName,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImage?.url,
        },
        {
          property: 'og:image:height',
          content: `${metaImage?.height ? metaImage.height : ''}`,
        },
        {
          property: 'og:image:width',
          content: `${metaImage?.width ? metaImage.width : ''}`,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'og:url',
          content: `${site.siteMetadata.siteUrl}/${slug ? slug : ''}`,
        },
        {
          name: 'twitter:card',
          content: metaTwitterCard,
        },
        {
          name: 'twitter:creator',
          content: datoCmsSite.globalSeo.twitterAccount,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]}
    >
      <link rel='icon' type='image/png' href='/assets/images/favicon.png' />
      <script type='application/ld+json'>{JSON.stringify(SDataSchema)}</script>
    </Helmet>
  )
}

export default Seo
