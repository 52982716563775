import React from 'react'
import styled, { css } from 'styled-components'
import { Box, BoxProps } from '../../../atoms'

export const BoxWithDot: React.FC<BoxProps & { as?: React.ElementType; dotColor: string; ordered: boolean }> = styled(
  Box
)<BoxProps & { as?: React.ElementType; dotColor: string; ordered: boolean }>`
  &::marker {
    color: ${({ theme }) => theme.colors.navy};
    font-size: 18px;
  }

  ${({ theme, dotColor, ordered }) =>
    !ordered &&
    css`
      &:before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 100%;
        position: absolute;
        top: 10px;
        left: -20px;
        background-color: ${theme.colors[dotColor] || dotColor};
      }
    `}
`
