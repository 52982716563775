import React from 'react'
//
import { StyledSocialButton } from './SocialButton.styled'
import { ImageBasicProps } from '../../types/images'

export type SocialButtonTypes = {
  buttonType?: 'purpleBlue'
}

export type SocialButtonProps = SocialButtonTypes & {
  name: string
  url: string
  icon: ImageBasicProps
}

const SocialButton: React.FC<SocialButtonProps> = ({ name, url, icon, buttonType }) => {
  return (
    <a href={url} target={'_blank'} rel={'noreferrer noopener'}>
      <StyledSocialButton title={name} buttonType={buttonType}>
        {icon && (
          <img
            src={icon.url}
            width={icon.width}
            height={icon.height}
            alt={icon.alt || name}
            draggable={'false'}
            style={{ display: 'block', maxWidth: '18px' }}
          />
        )}
      </StyledSocialButton>
    </a>
  )
}

SocialButton.defaultProps = {
  buttonType: 'purpleBlue',
  name: 'Social',
  url: '',
}

export default SocialButton
