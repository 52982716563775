import React from 'react'
// Types
import { SubMenuItemGroupSimpleProps } from '../../../../../types/menus'
// Components
import { ReactSVG } from 'react-svg'
import { Box, Link, Text, Image } from '../../../../../atoms'
import { SubMenuItemGroupSimpleWrapper, ItemElement, ItemInner, ItemIconWrapper } from './SubMenuItemGroupSimple.styled'

const SubMenuItemGroupSimple: React.FC<SubMenuItemGroupSimpleProps> = ({ mainColor, links }) => {
  return (
    <SubMenuItemGroupSimpleWrapper mb={3}>
      <ul>
        {links.map((l) => (
          <ItemElement as={'li'} key={l.id} my={5}>
            <Link link={l} style={{ display: 'block' }}>
              <ItemInner
                width={165}
                alignItems={'center'}
                mainColor={mainColor || 'purpleBlue'}
                borderRadius={'circle'}
              >
                {l.icon && (
                  <ItemIconWrapper
                    flex={'0 0 auto'}
                    width={28}
                    height={28}
                    mr={14}
                    mainColor={mainColor || 'purpleBlue'}
                  >
                    <ReactSVG
                      className='submenu-item-group-simple-icon-wrapper'
                      alt={l.icon.alt || l.name}
                      src={l.icon.url}
                      draggable={false}
                      width={'100%'}
                      height={'auto'}
                    />
                  </ItemIconWrapper>
                )}
                <Box flex={'1'}>
                  <Text color={'inherit'} fontSize={1} fontWeight={'semi'} lineHeight={1.2}>
                    {l.name}
                  </Text>
                </Box>
              </ItemInner>
            </Link>
          </ItemElement>
        ))}
      </ul>
    </SubMenuItemGroupSimpleWrapper>
  )
}

export default SubMenuItemGroupSimple
