import { MarginProps, TypographyProps, ColorProps } from 'styled-system'
import { StyledText } from './Text.styled'

export type TextProps = TypographyProps &
  ColorProps &
  MarginProps & { textTransform?: string; textDecoration?: string; whiteSpace?: string }

StyledText.defaultProps = {
  fontFamily: 'body',
  fontSize: 3,
  color: 'dark',
  fontWeight: 'light',
}

export default StyledText
