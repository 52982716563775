import styled from 'styled-components'
import { compose, margin, color, system, typography } from 'styled-system'

import { TextProps } from './Text'

export const StyledText = styled.p<TextProps>`
  line-height: 1.8;
  transition: all 0.25s ease-in-out;
  text-underline-offset: 2px;

  ${system({
    textTransform: true,
    textDecoration: true,
    whiteSpace: true,
  })}
  ${compose(typography, margin, color)}
`
