import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Box, Flex, Icons } from '../../../atoms'
import { IModal } from '../../../types/modal'
import ModalSection from './sections'

import ModalContext from './Modal.context'

interface ModalProps {
  showModal: boolean
  onRequestClose: () => void
  data: IModal
}

const Modal: React.FC<ModalProps> = ({ showModal, onRequestClose, data }) => {
  //Assume exists because of Dato configuration
  const contentLeft = data.contentLeft[0]
  const contentRight = data.contentRight[0]

  const backdropVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  return (
    <ModalContext.Provider value={{ closeModal: onRequestClose }}>
      <AnimatePresence exitBeforeEnter>
        {showModal && (
          <motion.div
            variants={backdropVariants}
            transition={{ type: 'tween', ease: 'linear', duration: 0.15 }}
            initial='hidden'
            animate='visible'
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 9999,
            }}
          >
            {/* Overlay */}
            <Box bg={'dark'} opacity={0.7} width={'100%'} height={'100%'} onClick={onRequestClose} />
            {/* Modal */}
            <Box bg={'white'} position={'absolute'} width={'100%'} height={'100%'} left={0} top={0}>
              <Flex height={'100%'}>
                {/* Left part */}
                <Box display={['none', 'none', 'block']} flex={'1 0'} height={'100%'} bg={'navyBlue'}>
                  <ModalSection sectionApiKey={contentLeft.model.apiKey} data={contentLeft} />
                </Box>
                {/* Right part */}
                <Box position={'relative'} flex={'1'} height={'100%'}>
                  <Flex
                    justifyContent={'center'}
                    alignItems={'center'}
                    position={'absolute'}
                    top={32}
                    right={32}
                    width={40}
                    height={40}
                    borderRadius={'circle'}
                    bg={'dark'}
                    color={'white'}
                    onClick={onRequestClose}
                    clickable
                  >
                    <Icons.Close />
                  </Flex>
                  <Box px={[24, 60, 80]} pt={70} pb={[24, 60, 80]} height={'100%'} overflow={'auto'}>
                    <ModalSection sectionApiKey={contentRight.model.apiKey} data={contentRight} />
                  </Box>
                </Box>
              </Flex>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </ModalContext.Provider>
  )
}

export default Modal
