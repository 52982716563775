import React from 'react'
// Types
import { SubMenuItemGroupProps } from '../../../../../types/menus'
// Components
import { Box, Link, Text, Image } from '../../../../../atoms'
import { SubMenuItemGroupWrapper, ItemElement, ItemInner, ItemIconWrapper } from './SubMenuItemGroup.styled'

const SubMenuItemGroup: React.FC<SubMenuItemGroupProps> = ({ label, mainColor, links }) => {
  return (
    <SubMenuItemGroupWrapper mb={3}>
      {!!label && (
        <Box mb={3} pl={2}>
          <Text textTransform={'uppercase'} color={'lightPurple'} fontWeight={'semi'} fontSize={13}>
            {label}
          </Text>
        </Box>
      )}
      <ul>
        {links.map((l) => (
          <ItemElement as={'li'} key={l.id} my={2}>
            <Link link={l} style={{ display: 'block' }}>
              <ItemInner
                width={340}
                p={2}
                alignItems={'center'}
                mainColor={mainColor || 'purpleBlue'}
                borderRadius={'circle'}
              >
                {l.thumbMini && (
                  <ItemIconWrapper
                    flex={'0 0 auto'}
                    width={48}
                    height={48}
                    borderRadius={'circle'}
                    mr={4}
                    mainColor={mainColor || 'purpleBlue'}
                    overflow={'hidden'}
                  >
                    <Image draggable={false} alt={l.thumbMini.alt || l.name} image={l.thumbMini.gatsbyImageData} />
                  </ItemIconWrapper>
                )}
                <Box flex={'1'}>
                  <Text color={'inherit'} fontSize={1} fontWeight={'semi'} lineHeight={1.2}>
                    {l.name}
                  </Text>
                </Box>
              </ItemInner>
            </Link>
          </ItemElement>
        ))}
      </ul>
    </SubMenuItemGroupWrapper>
  )
}

export default SubMenuItemGroup
