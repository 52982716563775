import React from 'react'
// Types
import { VideoProps } from '../../types/items'
// Components
import { Box } from '../Box'

const VideoElement: React.FC<VideoProps> = ({ provider, providerUid, title, width, height }) => {
  return (
    <Box position={'relative'} style={{ aspectRatio: '16/9' }}>
      {provider === 'youtube' && (
        <iframe
          width={width}
          height={height}
          src={`https://www.youtube.com/embed/${providerUid}`}
          title={title}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      )}
      {provider === 'vimeo' && (
        <iframe
          width={width}
          height={height}
          src={`https://player.vimeo.com/video/${providerUid}?h=d4a3a50265&color=6DD8FC&title=0&byline=0&portrait=0`}
          frameBorder='0'
          allow='autoplay; fullscreen; picture-in-picture'
          allowFullScreen
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      )}
    </Box>
  )
}

export default VideoElement
