import React from 'react'
import theme from '../../../theme'

function ArrowRight(props: React.SVGProps<SVGSVGElement> & { color?: string; size?: number }): JSX.Element {
  const { color, style, size, ...otherProps } = props
  return (
    <svg
      width={size ? size : 16}
      height={size ? size : 16}
      viewBox={'0 0 16 16'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <path
        d='M8.5 13l5-5m-5-5l5 5m0 0H3'
        stroke={color ? theme.colors[color] || color : 'currentColor'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default ArrowRight
