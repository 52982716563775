import React from 'react'
// Types
import { MainNavigationItemProps } from '../../../../types/menus'
// Components
import { Flex } from '../../../../atoms'
import NavigationItem from './NavigationItem'

interface NavigationProps {
  light?: boolean
  mainNavigation: MainNavigationItemProps[]
}

const Navigation: React.FC<NavigationProps> = ({ light = false, mainNavigation }) => {
  return (
    <Flex
      as={'nav'}
      display={['none', 'none', 'flex']}
      flex={'1'}
      justifyContent={'center'}
      alignItems={'stretch'}
      height={'100%'}
    >
      {mainNavigation.map((item) => (
        <NavigationItem key={item.id} data={item} light={light} />
      ))}
    </Flex>
  )
}

export default Navigation
