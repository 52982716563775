import styled from 'styled-components'
import { LoaderProps } from './Loader'
import { variant } from 'styled-system'

export const StyledLoader = styled.svg<LoaderProps>`
  display: block;
  color: currentColor;

  ${variant({
    prop: 'size',
    variants: {
      lg: {
        width: '24px',
        height: '24px',
      },
      sm: {
        width: '20px',
        height: '20px',
      },
      xs: {
        width: '16px',
        height: '16px',
      },
    },
  })}
`

export const StyledLoaderGradient = styled.div`
  width: 100%;
  height: 100%;
  background: conic-gradient(transparent, currentColor);
`

export const StyledPrimaryCircle = styled.circle<LoaderProps>`
  ${variant({
    prop: 'buttonType',
    variants: {
      dark: {
        fill: 'peach',
      },
      purpleBlue: {
        fill: 'peach',
      },
      peach: {
        fill: 'lightBlue',
      },
      outline: {
        fill: 'peach',
      },
      outlineWhite: {
        fill: 'peach',
      },
    },
  })}
`
