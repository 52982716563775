import { GatsbyImageBasicProps } from './images'

export interface PublicationProps {
  publishedAt: Date
  updatedAt: Date
  firstPublishedAt?: Date
}

export interface PublicationFormattedProps {
  createdAt: string
  firstPublishedAt: string
  updatedAt: string
}

export interface SEOProps {
  title: string
  description: string
  image: {
    url: string
    width: number
    height: number
  }
  twitterCard: string
}

interface PageInfoObject {
  id: string
  name: string
  slug: string
  icon?: {
    url: string
    alt: string
  }
  thumbMini?: GatsbyImageBasicProps
  headerDark: boolean
  seo: SEOProps
}

// Used for page templates
export type PageInfoProps = Pick<PageInfoObject, 'slug' | 'name' | 'headerDark' | 'seo'>
// Used for links
export type PageInfoLinkProps = Pick<PageInfoObject, 'id' | 'name' | 'slug'>
// Used for navigation
export type PageInfoNavigationLinkProps = Pick<PageInfoObject, 'id' | 'name' | 'slug' | 'icon' | 'thumbMini'>

export interface ExternalLinkProps {
  id: string
  name: string
  url: string
  icon?: {
    url: string
    alt: string
  }
  openNewTab: boolean
}

export interface PaginationContextProps {
  limit: number
  skip: number
  totalItems: number
  totalPages: number
  currentPage: number
  previousPagePath: string
  nextPagePath: string
}
export type PaginationProps = Pick<
  PaginationContextProps,
  'totalPages' | 'currentPage' | 'previousPagePath' | 'nextPagePath'
>

export function isInternalLink(link: PageInfoLinkProps | ExternalLinkProps): link is PageInfoLinkProps {
  return !!(link as PageInfoLinkProps).slug
}
