import { useStaticQuery, graphql } from 'gatsby'

interface SeoQuery {
  site: {
    siteMetadata: {
      siteUrl: string
    }
  }
  datoCmsSite: {
    globalSeo: {
      facebookPageUrl: string
      fallbackSeo: {
        title: string
        description: string
        twitterCard: string
        image: {
          url: string
          width: number
          height: number
        }
      }
      twitterAccount: string
      siteName: string
    }
  }
}

export const seoQuery = (): SeoQuery => {
  return useStaticQuery<SeoQuery>(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }

      datoCmsSite {
        globalSeo {
          facebookPageUrl
          fallbackSeo {
            description
            title
            twitterCard
            image {
              url
              height
              width
            }
          }
          twitterAccount
          siteName
        }
      }
    }
  `)
}
