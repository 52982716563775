import React from 'react'
// Types
import { PaginationProps } from '../../../types/pages'
import { Box, Button, Flex, Text } from '../../../atoms'
import { Link } from 'gatsby'

const Pagination: React.FC<
  PaginationProps & {
    prevLabel?: string
    nextLabel?: string
  }
> = ({ totalPages, currentPage, nextLabel = 'Next', prevLabel = 'Prev', nextPagePath, previousPagePath }) => {
  if (!!(totalPages && totalPages > 0)) {
    return (
      <Flex mt={4} justifyContent={['space-between', 'center']} alignItems={'center'}>
        <Box flex={'0 0 auto'} mr={4}>
          <Button
            label={prevLabel}
            size={'sm'}
            buttonType={'outline'}
            disabled={!previousPagePath}
            link={previousPagePath ? { id: 'pagination-prevPath', name: prevLabel, slug: previousPagePath } : undefined}
          />
        </Box>
        {previousPagePath && currentPage && (
          <Box display={['none', 'block']} flex={'0 0 auto'}>
            <Link to={`/${previousPagePath}`}>
              <Flex
                mx={1}
                justifyContent={'center'}
                alignItems={'center'}
                width={48}
                height={48}
                borderRadius={'circle'}
              >
                <Text textAlign={'center'} fontSize={2} fontWeight={'bold'}>
                  {currentPage - 1}
                </Text>
              </Flex>
            </Link>
          </Box>
        )}
        {currentPage && (
          <Flex
            flex={'0 0 auto'}
            mx={1}
            justifyContent={'center'}
            alignItems={'center'}
            bg={'purpleBlue'}
            width={48}
            height={48}
            borderRadius={'circle'}
          >
            <Text textAlign={'center'} color={'white'} fontSize={2} fontWeight={'bold'}>
              {currentPage}
            </Text>
          </Flex>
        )}
        {nextPagePath && currentPage && (
          <Box display={['none', 'block']} flex={'0 0 auto'}>
            <Link to={`/${nextPagePath}`}>
              <Flex
                mx={1}
                justifyContent={'center'}
                alignItems={'center'}
                width={48}
                height={48}
                borderRadius={'circle'}
              >
                <Text textAlign={'center'} fontSize={2} fontWeight={'bold'}>
                  {currentPage + 1}
                </Text>
              </Flex>
            </Link>
          </Box>
        )}
        <Box flex={'0 0 auto'} ml={4}>
          <Button
            label={nextLabel}
            size={'sm'}
            buttonType={'outline'}
            disabled={!nextPagePath}
            link={nextPagePath ? { id: 'pagination-nextPath', name: nextLabel, slug: nextPagePath } : undefined}
          />
        </Box>
      </Flex>
    )
  }
  return null
}

export default Pagination
