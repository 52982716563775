import { useStaticQuery, graphql } from 'gatsby'
// Types
import { ImageBasicProps } from '../../../types/images'
import { ButtonProps } from '../../../types/buttons'
import { ExternalLinkProps } from '../../../types/pages'
import { MainNavigationItemProps } from '../../../types/menus'
import { BannerProps } from '../../../types/global'

interface HeaderQuery {
  site: {
    globalSeo: {
      siteName: string
    }
  }
  header: {
    headerLogoDark: ImageBasicProps
    headerLogoLight: ImageBasicProps
    mainNavigation: MainNavigationItemProps[]
    login: ExternalLinkProps
    buttons: ButtonProps[]
    mobileButtons: ButtonProps[]
  }
  announcementBanner: {
    banner: BannerProps[]
  }
}

export const headerQuery = (): HeaderQuery => {
  return useStaticQuery<HeaderQuery>(graphql`
    query {
      site: datoCmsSite {
        ...siteNameFragment
      }
      header: datoCmsGlobal {
        headerLogoDark {
          alt
          url
          width
          height
        }
        headerLogoLight {
          alt
          url
          width
          height
        }
        mainNavigation {
          ...mainNavigationItemFragment
        }
        login: headerLogin {
          ...externalLinkFragment
        }
        buttons: headerButtons {
          ...buttonFragment
        }
        mobileButtons: headerMobileButtons {
          ...buttonFragment
        }
      }
      announcementBanner: datoCmsGlobal {
        banner {
          content
          identifier
          link
          linkText
          openNewTab
          backgroundColor {
            hex
          }
        }
      }
    }
  `)
}
