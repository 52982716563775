import * as React from 'react'
import theme from '../../../theme'
function Linkedin(props: React.SVGProps<SVGSVGElement & { color?: string }>): JSX.Element {
  const { color, style, ...otherProps } = props
  return (
    <svg
      width={18}
      height={18}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <path
        d='M15.337 15.337H12.67V11.16c0-.996-.018-2.278-1.387-2.278-1.39 0-1.602 1.085-1.602 2.206v4.249H7.014v-8.59h2.56v1.174h.036a2.806 2.806 0 012.526-1.387c2.704 0 3.202 1.778 3.202 4.091l-.001 4.712zM4.005 5.574a1.548 1.548 0 110-3.096 1.548 1.548 0 010 3.096zm1.333 9.763h-2.67v-8.59h2.67v8.59zM16.667.001H1.327A1.314 1.314 0 000 1.3V16.7A1.315 1.315 0 001.328 18h15.338A1.317 1.317 0 0018 16.7V1.298A1.316 1.316 0 0016.666 0'
        fill={color ? theme.colors[color] || color : 'currentColor'}
      />
    </svg>
  )
}
export default Linkedin
