import React from 'react'
// Types
import { MainNavigationItemProps } from '../../../../../types/menus'
// Components
import { Box, Link, Text, Icons } from '../../../../../atoms'
import { NavigationItemElement, NavigationItemInner, NavigationItemSubMenu } from './NavigationItem.styled'
import SubMenu from '../../SubMenu'

interface NavigationItemProps {
  light?: boolean
  data: MainNavigationItemProps
}

const NavigationItem: React.FC<NavigationItemProps> = ({ data, light = false }) => {
  return (
    <NavigationItemElement
      position={'relative'}
      px={[20, 20, 20, 28]}
      height={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Link link={data.link}>
        <NavigationItemInner alignItems={'center'} style={{ cursor: 'pointer' }}>
          <Box flex={'1'}>
            <Text color={light ? 'white' : 'dark'} fontSize={1} fontWeight={'semi'}>
              {data.label}
            </Text>
          </Box>
          {data.subMenu && (
            <Box flex={'0 0 auto'} ml={2}>
              <Icons.ChevronDown color={light ? 'white' : 'dark'} />
            </Box>
          )}
        </NavigationItemInner>
      </Link>

      {data.subMenu && (
        <NavigationItemSubMenu
          position={'absolute'}
          minWidth={220}
          top={'calc(100% - 8px)'}
          left={'50%'}
          p={5}
          borderColor={'dark'}
          borderStyle={'solid'}
          borderWidth={3}
          borderRadius={12}
          bg={'white'}
        >
          <SubMenu {...data.subMenu} />
        </NavigationItemSubMenu>
      )}

      {/* <SubMenu {...data.subMenu} />*/}
    </NavigationItemElement>
  )
}

export default NavigationItem
