import React, { PropsWithChildren, useMemo } from 'react'
import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'
// Theme
import theme from '../../../theme'
// Components
import { Link as GatsbyLink } from 'gatsby'
import { Box, Heading, Text } from '../../../atoms'
import { BoxWithDot } from './ContentMd.styled'

interface Props {
  content: string
  renderers?: any
  color?: string | keyof typeof theme.colors
  dotColor?: string
}

const ContentMd: React.FC<Props> = ({ content, renderers, color = 'dark', dotColor = 'purpleBlue' }) => {
  const defaultRenderers = useMemo(() => {
    return {
      p: (props: any) => (
        <Box my={2}>
          <Text {...props} color={color} />
        </Box>
      ),
      strong: (props: any) => <Text as={'strong'} fontWeight={'semi'} fontSize={'inherit'} {...props} />,
      em: (props: any) => <Text as={'em'} fontSize={'inherit'} fontStyle={'italic'} {...props} />,
      h1: ({ level, ...otherProps }: any) => (
        <Box my={8}>
          <Heading as={`h${level}`} {...otherProps} />
        </Box>
      ),
      h2: ({ level, ...otherProps }: any) => (
        <Box my={8}>
          <Heading as={`h${level}`} {...otherProps} />
        </Box>
      ),
      h3: ({ level, ...otherProps }: any) => (
        <Box my={8}>
          <Heading as={`h${level}`} {...otherProps} />
        </Box>
      ),
      h4: ({ level, ...otherProps }: any) => (
        <Box my={8}>
          <Heading as={`h${level}`} {...otherProps} />
        </Box>
      ),
      h5: ({ level, ...otherProps }: any) => (
        <Box my={5}>
          <Heading as={`h${level}`} {...otherProps} />
        </Box>
      ),
      h6: ({ level, ...otherProps }: any) => (
        <Box my={5}>
          <Heading as={`h${level}`} {...otherProps} />
        </Box>
      ),
      heading: ({ level, ...otherProps }: any) => (
        <Box my={level <= 4 ? 8 : 5}>
          <Heading as={`h${level}`} {...otherProps} />
        </Box>
      ),
      a: ({ href, ...otherProps }: { href: string | undefined }) => {
        if (href && (href.startsWith('/') || href.startsWith('?') || href.startsWith('#'))) {
          return (
            <GatsbyLink to={href} style={{ display: 'inline' }}>
              <Text as={'span'} color={'purpleBlue'} textDecoration={'underline'} {...otherProps} />
            </GatsbyLink>
          )
        }
        return (
          <a href={href} target='_blank' style={{ display: 'inline' }}>
            <Text as={'span'} color={'purpleBlue'} textDecoration={'underline'} {...otherProps} />
          </a>
        )
      },
      ol: ({ ...otherProps }: any) => <Box as={'ol'} pl={5} style={{ listStyleType: 'decimal' }} {...otherProps} />,
      ul: ({ ...otherProps }: any) => <Box as={'ul'} pl={5} {...otherProps} />,
      li: (props: PropsWithChildren<any>) => {
        return (
          <BoxWithDot as={'li'} position={'relative'} my={2} dotColor={dotColor} ordered={props.ordered}>
            <Text as={'span'} lineHeight={1.5} {...props} color={color} />
          </BoxWithDot>
        )
      },
      hr: () => <Box as={'hr'} height={1} width={'100%'} bg={'dark'} opacity={0.16} border={'none'} />,
      code: ({ inline, ...otherProps }: any) => {
        return (
          <Box
            as={'code'}
            display={'inline-block'}
            bg='rgb(245, 242, 240)'
            fontFamily={'monospace'}
            px={2}
            color={'dark'}
            fontSize={2}
            {...otherProps}
          />
        )
      },
      img: ({ src, alt }: PropsWithChildren<any>) => {
        return <img src={src} alt={alt || ''} style={{ maxWidth: '100%' }} />
      },
    }
  }, [color])

  return (
    <ReactMarkdown children={content} remarkPlugins={[breaks]} components={{ ...defaultRenderers, ...renderers }} />
  )
}
export default ContentMd
