import React from 'react'
import loadable from '@loadable/component'

//Types
import { ModalSectionBaseProps } from '../../../../types/modal-sections'
import { HubspotFormProps } from './HubspotForm/HubspotForm'
import { ContentLogoProps } from './ContentLogo/ContentLogo'

interface SectionProps {
  key?: string | number
  sectionApiKey: string
  data: any
}

/*
 We have to write a loadable line for every component to avoid crash during build
 time. loadable have an issue with dynamic import with gatsby.
*/
const SectionComponents: { [key: string]: React.FC<any> } = {
  Default: (props: ModalSectionBaseProps) => {
    const SectionComponent = loadable(() => import('./Default'))
    return <SectionComponent {...props} />
  },
  HubspotForm: (props: HubspotFormProps) => {
    const SectionComponent = loadable(() => import('./HubspotForm'))
    return <SectionComponent {...props} />
  },
  ContentLogo: (props: ContentLogoProps) => {
    const SectionComponent = loadable(() => import('./ContentLogo'))
    return <SectionComponent {...props} />
  },
}

const ModalSection: React.FC<SectionProps> = (props) => {
  //Here we convert snake case api key eg: ms_content_logo
  //to MsFeatureSimple then we remove the Ms to match with the component name
  const sectionComponentName = snake2Pascal(props.sectionApiKey).substring(2)
  const SectionComponent = SectionComponents[sectionComponentName] || SectionComponents['Default']
  return <SectionComponent {...props} />
}

export default ModalSection

//Utilities
function snake2Pascal(str: string) {
  return str
    .split('_')
    .map((s) => {
      return s.slice(0, 1).toUpperCase() + s.slice(1, s.length)
    })
    .join('')
}
