import * as React from 'react'
import theme from '../../../theme'
function Mail(props: React.SVGProps<SVGSVGElement & { color?: string }>): JSX.Element {
  const { color, style, ...otherProps } = props
  return (
    <svg
      width={18}
      height={14}
      viewBox='0 0 18 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 4.808V12a2 2 0 002 2h14a2 2 0 002-2V4.809l-7.844 3.683a2.721 2.721 0 01-2.312 0L0 4.808zm18-2.52V2a2 2 0 00-2-2H2a2 2 0 00-2 1.972v.297c0 .345.2.659.515.807l8.1 3.804a.907.907 0 00.77 0l8.1-3.804A.892.892 0 0018 2.288z'
        fill={color ? theme.colors[color] || color : 'currentColor'}
      />
    </svg>
  )
}
export default Mail
