import styled from 'styled-components'
import { variant } from 'styled-system'
//
import { SocialButtonTypes } from './SocialButton'

export const StyledSocialButton = styled.button<SocialButtonTypes>(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    appearance: 'none',
    outline: 'none',
    cursor: 'pointer',
    transition: 'all 0.25s ease-in-out',
    padding: 0,
    border: 'none',
  },
  variant({
    prop: 'buttonType',
    variants: {
      purpleBlue: {
        color: 'white',
        bg: 'purpleBlue',
        width: '40px',
        height: '40px',
        borderRadius: 'circle',
        '&:hover': {
          bg: 'purpleShade',
        },
      },
    },
  })
)
