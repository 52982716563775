import styled from 'styled-components'
import { rgba } from 'polished'
import { variant, margin } from 'styled-system'

import { ButtonProps } from './Button'

export const StyledButton = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  font-family: inherit;
  border-style: solid;
  border-width: 2px;
  outline: none;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  ${margin}

  &[disabled] {
    cursor: not-allowed;
  }

  .btn-icon-scroll {
    transform: rotate(90deg);
  }

  &:hover {
    .btn-icon {
      transform: translateX(4px);
    }

    .btn-icon-scroll {
      transform: rotate(90deg) translateX(2px);
    }
  }

  ${({ buttonType }) =>
    variant({
      prop: 'size',
      variants: {
        lg: {
          fontSize: !buttonType.startsWith('text') ? 2 : 3,
          fontWeight: 'semi',
          lineHeight: 1.2,
          minHeight: !buttonType.startsWith('text') ? '52px' : 'unset',
          paddingX: !buttonType.startsWith('text') ? 6 : 0,
          paddingY: !buttonType.startsWith('text') ? 0 : 1,
        },
        sm: {
          fontSize: !buttonType.startsWith('text') ? 1 : 3,
          fontWeight: 'semi',
          lineHeight: 1.2,
          minHeight: !buttonType.startsWith('text') ? '48px' : 'unset',
          paddingX: !buttonType.startsWith('text') ? 6 : 0,
          paddingY: !buttonType.startsWith('text') ? 0 : 1,
        },
        xs: {
          fontSize: !buttonType.startsWith('text') ? 1 : 3,
          fontWeight: 'semi',
          lineHeight: 1.2,
          minHeight: !buttonType.startsWith('text') ? '40px' : 'unset',
          paddingX: !buttonType.startsWith('text') ? 5 : 0,
          paddingY: !buttonType.startsWith('text') ? 0 : 1,
        },
      },
    })}

  ${({ theme, isLoading, fluid, color }) =>
    variant({
      prop: 'buttonType',
      variants: {
        dark: {
          width: fluid ? '100%' : ['100%', '100%', 'auto'],
          color: 'white',
          bg: 'dark',
          borderColor: 'dark',
          borderRadius: 'circle',
          '&:hover:not([disabled])': {
            bg: 'darkShade',
            borderColor: 'darkShade',
          },
          '&[disabled]': {
            span: {
              opacity: 0.4,
            },
          },
        },
        purpleBlue: {
          width: fluid ? '100%' : ['100%', '100%', 'auto'],
          color: 'white',
          bg: 'purpleBlue',
          borderColor: 'purpleBlue',
          borderRadius: 'circle',
          '&:hover:not([disabled])': {
            bg: 'purpleShade',
            borderColor: 'purpleShade',
          },
          '&[disabled]': {
            span: {
              opacity: 0.4,
            },
          },
        },
        peach: {
          width: fluid ? '100%' : ['100%', '100%', 'auto'],
          color: 'dark',
          bg: 'peach',
          borderColor: 'peach',
          borderRadius: 'circle',
          '&:hover:not([disabled])': {
            color: 'navyBlue',
            bg: 'peachShade',
            borderColor: 'peachShade',
          },
          '&[disabled]': {
            span: {
              opacity: 0.4,
            },
          },
        },
        outline: {
          width: fluid ? '100%' : ['100%', '100%', 'auto'],
          color: 'dark',
          bg: 'transparent',
          borderColor: 'dark',
          borderRadius: 'circle',
          '&:hover:not([disabled])': {
            bg: rgba(theme.colors.white, 0.12),
          },
          '&[disabled]': {
            opacity: isLoading ? 1 : 0.4,
          },
        },
        outlineWhite: {
          width: fluid ? '100%' : ['100%', '100%', 'auto'],
          color: 'white',
          bg: 'transparent',
          borderColor: 'white',
          borderRadius: 'circle',
          '&:hover:not([disabled])': {
            bg: rgba(theme.colors.white, 0.12),
          },
          '&[disabled]': {
            opacity: isLoading ? 1 : 0.4,
          },
        },
        outlinePurpleBlue: {
          width: fluid ? '100%' : ['100%', '100%', 'auto'],
          color: 'purpleBlue',
          bg: 'transparent',
          borderColor: 'purpleBlue',
          borderRadius: 'circle',
          '&:hover:not([disabled])': {
            bg: rgba(theme.colors.purpleBlue, 0.12),
          },
          '&[disabled]': {
            opacity: isLoading ? 1 : 0.4,
          },
        },
        outlineLightBlue: {
          width: fluid ? '100%' : ['100%', '100%', 'auto'],
          color: 'lightBlue',
          bg: 'transparent',
          borderColor: 'lightBlue',
          borderRadius: 'circle',
          '&:hover:not([disabled])': {
            bg: rgba(theme.colors.lightBlue, 0.12),
          },
          '&[disabled]': {
            opacity: isLoading ? 1 : 0.4,
          },
        },
        text: {
          position: 'relative',
          color: color || 'purpleBlue',
          bg: 'transparent',
          border: 'none',
          '&::before, &::after': {
            content: JSON.stringify(''),
            position: 'absolute',
            width: '100%',
            height: '2px',
            borderRadius: '4px',
            bg: color || 'purpleBlue',
            bottom: 0,
            left: 0,
          },
          '&::before': {
            opacity: 0.24,
          },
          '&::after': {
            width: '0%',
            transition: 'width 0.25s ease-in-out',
          },
          '&:hover:not([disabled])': {
            '&::after': {
              width: '100%',
            },
          },
          '&[disabled]': {
            span: {
              opacity: 0.4,
            },
          },
        },
        textLight: {
          position: 'relative',
          color: 'dark',
          bg: 'transparent',
          border: 'none',
          fontWeight: 'normal',
          '&::before, &::after': {
            content: JSON.stringify(''),
            position: 'absolute',
            width: '100%',
            height: '1px',
            borderRadius: '4px',
            bg: 'dark',
            bottom: 0,
            left: 0,
          },
          '&::before': {
            opacity: 0.24,
          },
          '&::after': {
            width: '0%',
            bg: 'purpleBlue',
            transition: 'width 0.25s ease-in-out',
          },
          '&:hover:not([disabled])': {
            color: 'purpleBlue',
            '&::after': {
              width: '100%',
            },
          },
          '&[disabled]': {
            span: {
              opacity: 0.4,
            },
          },
        },
      },
    })}
`
