import React from 'react'
import styled from 'styled-components'
// Types
import { SubMenuItemGroupColors } from '../../../../../types/menus'
// Components
import { Flex, FlexProps, Box, BoxProps } from '../../../../../atoms'

export const SubMenuItemGroupSimpleWrapper: React.FC<BoxProps> = styled(Box)<BoxProps>`
  &:last-child {
    margin-bottom: 0;
  }
`

export const ItemElement: React.FC<BoxProps & { as?: React.ElementType }> = styled(Box)<
  BoxProps & { as?: React.ElementType }
>`
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

export const ItemInner: React.FC<FlexProps & { mainColor: SubMenuItemGroupColors }> = styled(Flex)<
  FlexProps & { mainColor: SubMenuItemGroupColors }
>`
  color: ${({ theme }) => theme.colors.dark};

  .submenu-item-group-simple-icon-wrapper svg * {
    fill: ${({ theme }) => theme.colors.navy};
  }

  &:hover {
    color: ${({ theme, mainColor }) => theme.colors[mainColor]};

    .submenu-item-group-simple-icon-wrapper svg * {
      fill: ${({ theme, mainColor }) => theme.colors[mainColor]};
    }
  }
`

export const ItemIconWrapper: React.FC<BoxProps & { mainColor: SubMenuItemGroupColors }> = styled(Box)<
  BoxProps & { mainColor: SubMenuItemGroupColors }
>`
  .submenu-item-group-simple-icon-wrapper,
  .submenu-item-group-simple-icon-wrapper > div {
    width: 100%;
    height: 100%;
  }

  .submenu-item-group-simple-icon-wrapper svg {
    width: 28px;
    height: auto;
  }
  .submenu-item-group-simple-icon-wrapper svg * {
    transition: fill 0.25s ease-in-out;
  }
`
