import React, { useRef } from 'react'
// Components
import PreFooter from '../PreFooter'
import Footer from '../Footer'
import Header from '../Header'

interface LayoutProps {
  headerDark?: boolean
  prefooter?: boolean
}

const Layout: React.FC<LayoutProps> = ({ children, headerDark, prefooter = true }) => {
  const mainContainer = useRef<HTMLElement>(null)

  return (
    <>
      <Header dark={headerDark} />
      <main ref={mainContainer} style={{ paddingTop: 'var(--banner-height)', transition: 'padding 0.25s ease-in-out' }}>
        {children}
      </main>
      {prefooter && <PreFooter />}
      <Footer hasPrefooter={prefooter} />
    </>
  )
}

export default Layout
