import ArrowRight from './ArrowRight'
import Burger from './Burger'
import ChevronDown from './ChevronDown'
import Close from './Close'
import Linkedin from './Linkedin'
import Mail from './Mail'
import Twitter from './Twitter'

const Icons = {
  ArrowRight,
  Burger,
  ChevronDown,
  Close,
  Linkedin,
  Mail,
  Twitter,
}

export default Icons
