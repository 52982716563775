import styled from 'styled-components'
import { variant, compose, margin, color, typography } from 'styled-system'

import { HeadingProps } from './Heading'

export const StyledHeading = styled.h1<HeadingProps>`
  ${variant({
    prop: 'as',
    variants: {
      h1: {
        fontFamily: 'hoves',
        fontSize: ['48px', '56px', '72px'],
        lineHeight: ['56px', '64px', '82px'],
        fontWeight: 'semi',
        color: 'dark',
      },
      h2: {
        fontFamily: 'hoves',
        fontSize: ['48px', '56px', '64px'],
        lineHeight: ['56px', '64px', '72px'],
        fontWeight: 'medium',
        color: 'dark',
      },
      h3: {
        fontFamily: 'hoves',
        fontSize: ['32px', '40px', '56px'],
        lineHeight: ['40px', '48px', '64px'],
        fontWeight: 'semi',
        color: 'dark',
      },
      h4: {
        fontFamily: 'hoves',
        fontSize: ['28px', '34px', '40px'],
        lineHeight: ['34px', '40px', '48px'],
        fontWeight: 'semi',
        color: 'dark',
      },
      h5: {
        fontFamily: 'hoves',
        fontSize: '28px',
        lineHeight: '34px',
        fontWeight: 'semi',
        color: 'dark',
      },
      h6: {
        fontFamily: 'hoves',
        fontSize: ['20px', '20px', '24px'],
        lineHeight: ['32px', '32px', '40px'],
        fontWeight: 'normal',
        color: 'dark',
      },
    },
  })}

  ${compose(typography, margin, color)}
`
