import { useStaticQuery, graphql } from 'gatsby'
import { ButtonProps } from '../../../types/buttons'
import { GatsbyImageBasicProps } from '../../../types/images'

interface PreFooterQuery {
  prefooter: {
    headline: string
    subHeadline?: string
    buttons: ButtonProps[]
    background: GatsbyImageBasicProps
    backgroundColor: {
      hex: string
    }
  }
}

export const preFooterQuery = (): PreFooterQuery => {
  return useStaticQuery<PreFooterQuery>(graphql`
    query {
      prefooter: datoCmsGlobal {
        headline: prefooterHeadline
        subHeadline: prefooterSubHeadline
        buttons: prefooterButtons {
          ...buttonFragment
        }
        background: prefooterBackground {
          alt
          title
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
        backgroundColor: prefooterBackgroundColor {
          hex
        }
      }
    }
  `)
}
