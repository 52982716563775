import React, { useEffect } from 'react'

export interface HubspotFormProps {
  region: string
  portalId: string
  formId: string
  onFormSubmit?: () => void
  onFormSubmitted?: () => void
  forNewsletter?: boolean
}

const HubspotForm: React.FC<HubspotFormProps> = ({
  region,
  portalId,
  formId,
  onFormSubmit,
  onFormSubmitted,
  forNewsletter = false,
}) => {
  const idForm = `modalHubspotForm-${region}-${portalId}-${formId}`

  useEffect(() => {
    if (!window) {
      return
    }
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    script.async = true
    const submitHandler = (event: any) => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
        if (onFormSubmit) {
          onFormSubmit()
        }
      }
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        //Form is submitted for real
        if (onFormSubmitted) {
          onFormSubmitted()
        }
      }
    }
    window.addEventListener('message', submitHandler)
    document.body.appendChild(script)
    script.addEventListener('load', () => {
      //We use ts ignore here because window.hbspt does not exists
      //But here it exists because of the loading of hubsport script
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (typeof window !== 'undefined' && window.hbspt) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.hbspt.forms.create({
          region: region,
          portalId: portalId,
          formId: formId,
          target: `#${idForm}`,
        })
      }
    })
    return () => {
      script.remove()
      const formElement = document.getElementById(idForm)
      if (formElement) {
        formElement.innerHTML = ''
      }
      window.removeEventListener('message', submitHandler)
    }
  }, [region, portalId, formId])
  return (
    <div
      id={idForm}
      className={`hubspot-form-container ${forNewsletter ? 'hubspot-form-container--newsletter' : ''}`}
    />
  )
}

export default HubspotForm
