import styled, { css } from 'styled-components'
import {
  compose,
  position,
  PositionProps,
  space,
  SpaceProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  background,
  BackgroundProps,
  border,
  BorderProps,
  shadow,
  ShadowProps,
} from 'styled-system'

export type FlexProps = PositionProps &
  SpaceProps &
  ColorProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  ShadowProps & {
    transition?: string
    clickable?: boolean
  }

const Flex = styled.div<FlexProps>`
  ${compose(position, space, color, layout, flexbox, background, border, shadow)}

  ${({ transition }) =>
    transition &&
    css`
      transition: ${transition};
    `}

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`

Flex.defaultProps = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}

export default Flex
