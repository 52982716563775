import React from 'react'
import styled from 'styled-components'
import { Box, BoxProps } from '../../../../atoms'

export const BannerWrapper: React.FC<BoxProps & { ref: React.Ref<HTMLDivElement> }> = styled(Box)``

export const LinkWrapper = styled(Box)<BoxProps>`
  line-height: 0;
`

export const LinkArrow = styled.span`
  display: inline-block;
  vertical-align: middle;
  transition: transform 0.25s ease-in-out;
  color: inherit;
  margin-left: ${({ theme }) => `${theme.space[1]}px`};
  line-height: 1.2;
  font-size: 14px;

  ${LinkWrapper}:hover & {
    transform: translateX(2px);
  }
`
