import React, { MouseEvent } from 'react'
// Types
import { MarginProps } from 'styled-system'
import { ButtonType } from '../../types/buttons'
import { ExternalLinkProps, PageInfoLinkProps } from '../../types/pages'
import { IModal } from '../../types/modal'
// Utils
import { scrollToAnchor } from './Button.utils'
// Components
import Link from '../Link'
import Icons from '../Icons'
import { Box } from '../Box'
import { StyledButton } from './Button.styled'
import Loader from './Loader'

export type ButtonProps = MarginProps & {
  id?: string
  label?: string
  buttonType: ButtonType
  size?: 'lg' | 'sm' | 'xs'
  disabled?: boolean
  isLoading?: boolean
  onClick?: (event: MouseEvent) => void
  link?: PageInfoLinkProps | ExternalLinkProps | IModal
  scrollTo?: string
  fluid?: boolean
  buttonId?: string
  color?: string
}

const Button: React.FC<ButtonProps> = (props) => {
  //Destructure id to avoid have it in otherProps
  const { id, buttonId, children, label, link, isLoading, fluid, size, buttonType, scrollTo, onClick, ...otherProps } =
    props
  return (
    <Link link={link} fluid={fluid} id={link ? buttonId : undefined}>
      <StyledButton
        buttonType={buttonType}
        isLoading={isLoading}
        fluid={fluid}
        size={size}
        {...otherProps}
        onClick={!!scrollTo ? () => scrollToAnchor(scrollTo) : onClick}
        id={!link ? buttonId : undefined}
      >
        {isLoading ? (
          <Loader size={size} buttonType={buttonType} />
        ) : (
          <>
            <span>{children || label}</span>
            {size === 'lg' && buttonType.includes('outline') && !!scrollTo && (
              <Box className={'btn-icon-scroll'} ml={2} transition={'all 0.3s ease-in-out'}>
                <Icons.ArrowRight />
              </Box>
            )}
            {size === 'lg' && !buttonType.includes('outline') && !buttonType.includes('text') && (
              <Box className={!!scrollTo ? 'btn-icon-scroll' : 'btn-icon'} ml={2} transition={'all 0.3s ease-in-out'}>
                <Icons.ArrowRight />
              </Box>
            )}
          </>
        )}
      </StyledButton>
    </Link>
  )
}

Button.defaultProps = {
  buttonType: 'dark',
  size: 'lg',
  isLoading: false,
  disabled: false,
  onClick: undefined,
}

export default Button
