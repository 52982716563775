import styled from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'
import { mediaQueries } from '../../../theme'

export const PreFooterCol = styled(Col)`
  padding-left: 0;
  padding-right: 0;

  ${mediaQueries.greaterThan('sm')`
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  `}
`
