import React from 'react'
import theme from '../../../theme'

function Close(props: React.SVGProps<SVGSVGElement> & { color?: string }): JSX.Element {
  const { color, style, ...otherProps } = props

  return (
    <svg
      width={16}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <rect
        x={1.637}
        y={0.222}
        width={20}
        height={2}
        rx={1}
        transform='rotate(45 1.637 .222)'
        fill={color ? theme.colors[color] || color : 'currentColor'}
      />
      <rect
        x={0.223}
        y={14.364}
        width={20}
        height={2}
        rx={1}
        transform='rotate(-45 .223 14.364)'
        fill={color ? theme.colors[color] || color : 'currentColor'}
      />
    </svg>
  )
}

export default Close
