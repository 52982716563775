import React, { useState } from 'react'
// Types
import { MainNavigationItemProps } from '../../../../types/menus'
import { ButtonProps } from '../../../../types/buttons'
// Components
import { Flex, Box, Button, Text, Link } from '../../../../atoms'
import { MenuMobileNavigationWrapper, MenuMobileButtonWrapper, MenuMobileItemChevron } from './MenuMobile.styled'
import SubMenu from '../SubMenu'

interface MenuMobileProps {
  mainNavigation: MainNavigationItemProps[]
  buttons: ButtonProps[]
  closeMenu: () => void
}

const MenuMobile: React.FC<MenuMobileProps> = ({ mainNavigation, buttons, closeMenu }) => {
  const [mainItemOpen, setMainItemOpen] = useState('')

  return (
    <Box
      display={['block', 'block', 'none']}
      position={'fixed'}
      top={0}
      width={'100%'}
      height={'100%'}
      bg={'white'}
      zIndex={1}
      pt={80}
    >
      <MenuMobileNavigationWrapper position={'relative'} flexDirection={'column'} alignItems={'center'} height={'100%'}>
        {/* Navigation items */}
        <Box flex={'1'} overflow={'auto'} width={'100%'}>
          <Box as={'nav'} maxWidth={780} mx={'auto'} px={18} py={5}>
            {mainNavigation.map((item, i, arr) => (
              <Box
                key={item.id}
                borderBottomWidth={i + 1 < arr.length ? '1px' : undefined}
                borderBottomStyle={'solid'}
                borderColor={'greyLight'}
                aria-expanded={mainItemOpen === item.id ? 'true' : 'false'}
                aria-controls={item.id}
                onClick={() => (mainItemOpen === item.id ? setMainItemOpen('') : setMainItemOpen(item.id))}
              >
                <Link link={item.link} style={{ display: 'block' }}>
                  <Flex py={6} justifyContent={'space-between'} alignItems={'center'}>
                    <Box flex={'1'}>
                      <Text
                        fontWeight={'semi'}
                        lineHeight={1.2}
                        color={mainItemOpen === item.id ? 'purpleBlue' : 'dark'}
                      >
                        {item.label}
                      </Text>
                    </Box>
                    {item.subMenu && (
                      <Flex
                        justifyContent={'center'}
                        alignItems={'center'}
                        flex={'0 0 auto'}
                        ml={4}
                        width={24}
                        height={24}
                        borderRadius={'circle'}
                        bg={'purpleBlueLight'}
                        color={'purpleBlue'}
                      >
                        <MenuMobileItemChevron open={mainItemOpen === item.id} />
                      </Flex>
                    )}
                  </Flex>
                </Link>

                {item.subMenu && (
                  <Box id={item.id} display={mainItemOpen === item.id ? 'block' : 'none'} pb={6}>
                    <SubMenu {...item.subMenu} />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>

        {/* Buttons */}
        {buttons.length > 0 && (
          <MenuMobileButtonWrapper
            position={'relative'}
            flex={'0 0 auto'}
            p={18}
            zIndex={2}
            width={'100%'}
            maxWidth={780}
          >
            {buttons.map((b: ButtonProps, i: number) => (
              <Box key={b.id} mt={i > 0 ? 4 : 0} onClick={closeMenu}>
                <Button fluid {...b} />
              </Box>
            ))}
          </MenuMobileButtonWrapper>
        )}
      </MenuMobileNavigationWrapper>
    </Box>
  )
}
export default MenuMobile
