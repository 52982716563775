import React from 'react'
// Types
import { PageInfoProps } from '../../../types/pages'
// Components
import { CategoryLinkElement, CategoryLinkText } from './CategoryLink.styled'

interface CategoryLinkProps {
  name: string
  pageInfo: PageInfoProps
  partiallyActive?: boolean
}

const CategoryLink: React.FC<CategoryLinkProps> = ({ pageInfo, name, partiallyActive = true }) => {
  return (
    <CategoryLinkElement to={`/${pageInfo.slug}`} activeClassName={'active'} partiallyActive={partiallyActive}>
      <CategoryLinkText fontSize={2} fontWeight={'semi'} lineHeight={1.8}>
        {name}
      </CategoryLinkText>
    </CategoryLinkElement>
  )
}

export default CategoryLink
