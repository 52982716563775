import React from 'react'
// Types
import { ImageBasicProps } from '../../../../types/images'
// Components
import { ReactSVG } from 'react-svg'
import { Link } from 'gatsby'
import { Box } from '../../../../atoms'

interface LogoProps {
  logoDark: ImageBasicProps
  logoLight: ImageBasicProps
  siteName: string
  light?: boolean
}

const Logo: React.FC<LogoProps> = ({ logoDark, logoLight, siteName, light }) => {
  function afterInjection(err: Error | null, svg: SVGSVGElement | undefined) {
    if (!svg) {
      return
    }
    const animates = [
      ...Array.from(svg.getElementsByTagName('animate')),
      ...Array.from(svg.getElementsByTagName('animateTransform')),
    ]
    const idAnimation = animates.find((a) => !!a.id)?.id
    if (!idAnimation) {
      return
    }
    const newIdAnimation = `${idAnimation}${Math.floor(Math.random() * 1000)}`
    for (const a of animates) {
      if (!a.id) {
        const beginAttr = a.getAttribute('begin')
        if (beginAttr) {
          a.setAttribute('begin', beginAttr.replace(idAnimation, newIdAnimation))
        }
      }
      if (a.id === idAnimation) {
        a.id = newIdAnimation
      }
    }
  }

  return (
    <Link to={'/'} title={siteName}>
      <Box display={light ? 'none' : 'block'}>
        <ReactSVG
          src={logoDark.url}
          style={{ width: logoDark.width, height: logoDark.height }}
          draggable={false}
          afterInjection={afterInjection}
        />
      </Box>
      <Box display={light ? 'block' : 'none'}>
        <ReactSVG
          src={logoLight.url}
          style={{ width: logoLight.width, height: logoLight.height }}
          draggable={false}
          afterInjection={afterInjection}
        />
      </Box>
    </Link>
  )
}

export default React.memo(Logo)
