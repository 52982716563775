import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
// Types
import { SubMenuItemGroupColors } from '../../../../../types/menus'
// Components
import { Flex, FlexProps, Box, BoxProps } from '../../../../../atoms'

export const SubMenuItemGroupWrapper: React.FC<BoxProps> = styled(Box)<BoxProps>`
  &:last-child {
    margin-bottom: 0;
  }
`

export const ItemElement: React.FC<BoxProps & { as?: React.ElementType }> = styled(Box)<
  BoxProps & { as?: React.ElementType }
>`
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

export const ItemInner: React.FC<FlexProps & { mainColor: SubMenuItemGroupColors }> = styled(Flex)<
  FlexProps & { mainColor: SubMenuItemGroupColors }
>`
  background-color: transparent;
  transition: background-color 0.25s ease-in-out;
  color: ${({ theme }) => theme.colors.dark};

  &:hover {
    background-color: ${({ theme, mainColor }) =>
      rgba(theme.colors[mainColor], mainColor === 'purpleBlue' || mainColor === 'purple' ? 0.05 : 0.1)};
    color: ${({ theme, mainColor }) => theme.colors[mainColor]};
  }
`

export const ItemIconWrapper: React.FC<BoxProps & { mainColor: SubMenuItemGroupColors }> = styled(Box)<
  BoxProps & { mainColor: SubMenuItemGroupColors }
>`
  background-color: ${({ theme, mainColor }) => rgba(theme.colors[mainColor], 0.75)};
`
