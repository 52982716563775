import React from 'react'
import theme from '../../../theme'

function ChevronDown(props: React.SVGProps<SVGSVGElement> & { color?: string }): JSX.Element {
  const { color, style, ...otherProps } = props
  return (
    <svg
      width={10}
      height={6}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <path
        d='M9 1L5 5 1 1'
        stroke={color ? theme.colors[color] || color : 'currentColor'}
        strokeWidth={1.6}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ChevronDown
