import React from 'react'
import styled from 'styled-components'
//Components
import { Text, TextProps } from '../../../atoms'

export const FooterLinkText: React.FC<TextProps> = styled(Text)<TextProps>({
  '&:hover': {
    opacity: 1,
  },
})

export const FooterCreditsText: React.FC<TextProps & { as?: React.ElementType }> = styled(Text)<
  TextProps & { as?: React.ElementType }
>({
  '&:hover': {
    opacity: 0.8,
  },
})
