import React, { DetailedHTMLProps, ImgHTMLAttributes, JSXElementConstructor } from 'react'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'

type ImageProps = GatsbyImageProps | DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

function isGatsbyImageProps(
  props: GatsbyImageProps | DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
): props is GatsbyImageProps {
  return (props as GatsbyImageProps).image !== undefined
}

const Image: JSXElementConstructor<ImageProps> = (props) => {
  if (isGatsbyImageProps(props)) {
    const style = props.style
    const computedStyle = {
      display: 'block',
      zIndex: 1,
      ...style,
    }
    return <GatsbyImage {...props} style={computedStyle} />
  } else {
    const style = props.style
    const computedStyle = {
      maxWidth: '100%',
      display: 'block',
      ...style,
    }
    return <img {...props} style={computedStyle} />
  }
}

export default Image
