import React, { useState, useEffect } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
// Types
import { ButtonProps } from '../../../types/buttons'
// Utils
import { headerQuery } from './Header.utils'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Button, Link, Text, Icons } from '../../../atoms'
import { HeaderRoot, HeaderElement } from './Header.styled'
import Logo from './Logo'
import Navigation from './Navigation'
import MenuMobile from './MenuMobile'
import AnnouncementBanner from './AnnouncementBanner'

interface HeaderProps {
  dark?: boolean
}

const Header: React.FC<HeaderProps> = ({ dark }) => {
  const { site, header, announcementBanner } = headerQuery()

  const [isScrolled, setIsScrolled] = useState(false)
  const [menuMobileOpen, setMenuMobileOpen] = useState(false)

  useScrollPosition(({ currPos }) => {
    if (currPos.y <= -50) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }, [])

  useEffect(() => {
    const body = document.querySelector('body')
    if (body) {
      if (menuMobileOpen) {
        body.style.overflow = 'hidden'
      } else {
        body.style.overflow = ''
      }
    }
  }, [menuMobileOpen])

  return (
    <HeaderRoot>
      {announcementBanner?.banner && announcementBanner?.banner.length > 0 && (
        <AnnouncementBanner {...announcementBanner.banner[0]} />
      )}
      <HeaderElement
        className={isScrolled || menuMobileOpen ? (dark ? 'header-with-bg--dark' : 'header-with-bg--white') : undefined}
      >
        <Box position={'relative'} height={'100%'} zIndex={2}>
          <Grid style={{ height: '100%' }}>
            <Row style={{ height: '100%' }}>
              <Col xs={12} style={{ height: '100%' }}>
                <Flex height={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                  {/* Logo */}
                  <Box flex={'0 0 auto'} mr={2}>
                    <Logo
                      logoDark={header.headerLogoDark}
                      logoLight={header.headerLogoLight}
                      siteName={site.globalSeo.siteName}
                      light={dark && !menuMobileOpen}
                    />
                  </Box>
                  {/* Navigation */}
                  <Navigation light={dark} mainNavigation={header.mainNavigation} />
                  {/* Buttons */}
                  {(header.login || header.buttons) && (
                    <Flex ml={2} alignItems={'center'} display={['none', 'none', 'flex']} flex={'0 0 auto'}>
                      {header.login && (
                        <Box mr={6}>
                          <Link link={header.login}>
                            <Text fontSize={1} fontWeight={'semi'} color={dark && !menuMobileOpen ? 'white' : 'dark'}>
                              {header.login.name}
                            </Text>
                          </Link>
                        </Box>
                      )}
                      {header.buttons.map((b: ButtonProps, i: number) => (
                        <Button key={b.id} ml={i > 0 ? 4 : 0} size={'xs'} {...b} />
                      ))}
                    </Flex>
                  )}

                  {/* Mav mobile */}
                  <Flex
                    display={['flex', 'flex', 'none']}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={40}
                    height={40}
                    bg={'purpleBlue'}
                    color={'white'}
                    borderRadius={'circle'}
                    flex={'0 0 auto'}
                    onClick={() => setMenuMobileOpen(!menuMobileOpen)}
                  >
                    {menuMobileOpen ? <Icons.Close /> : <Icons.Burger />}
                  </Flex>
                </Flex>
              </Col>
            </Row>
          </Grid>
        </Box>
        {menuMobileOpen && (
          <MenuMobile
            mainNavigation={header.mainNavigation}
            buttons={header.mobileButtons}
            closeMenu={() => setMenuMobileOpen(false)}
          />
        )}
      </HeaderElement>
    </HeaderRoot>
  )
}
export default Header
