import React from 'react'
// Utils
import { preFooterQuery } from './PreFooter.utils'
// Components
import { Grid, Row } from 'react-styled-flexboxgrid'
import { Flex, Box, Button, Heading, Text, Image } from '../../../atoms'
import { PreFooterCol } from './PreFooter.styled'

const PreFooter: React.FC = () => {
  const {
    prefooter: { headline, subHeadline, buttons, background, backgroundColor },
  } = preFooterQuery()

  return (
    <Box position={'relative'} zIndex={2} as={'section'} mt={[64, 80, 120]} mb={[0, -200]}>
      <Grid>
        <Row>
          <PreFooterCol xs={12}>
            <Box
              position={'relative'}
              px={[18, 64, 80, 120]}
              py={[80, 80, 80, 120]}
              borderRadius={[0, 'large']}
              bg={backgroundColor?.hex || 'white'}
              overflow={'hidden'}
              zIndex={1}
            >
              {background && (
                <Box
                  position={'absolute'}
                  top={0}
                  left={0}
                  width={'100%'}
                  height={'100%'}
                  zIndex={1}
                  overflow={'hidden'}
                >
                  <Image
                    draggable={false}
                    alt={background.alt || ''}
                    image={background.gatsbyImageData}
                    style={{ width: '100%', height: '100%' }}
                  />
                </Box>
              )}
              <Box position={'relative'} zIndex={2}>
                <Box>
                  <Heading as={'h2'} color={'white'} fontWeight={['semi', 'medium']} textAlign={'center'}>
                    {headline}
                  </Heading>
                </Box>
                {!!subHeadline && (
                  <Box mt={4}>
                    <Text color={'white'} textAlign={'center'}>
                      {subHeadline}
                    </Text>
                  </Box>
                )}
                {buttons && buttons.length > 0 && (
                  <Flex justifyContent={'center'} alignItems={'center'} mt={5} flexWrap={'wrap'}>
                    {buttons.map((b) => (
                      <Box key={b.id} flex={['0 0 100%', '0 0 auto']} mx={[0, 4]} my={2}>
                        <Button fluid {...b} />
                      </Box>
                    ))}
                  </Flex>
                )}
              </Box>
            </Box>
          </PreFooterCol>
        </Row>
      </Grid>
    </Box>
  )
}
export default PreFooter
