import React from 'react'
import theme from '../../theme'

function Logo(props: React.SVGProps<SVGSVGElement> & { color?: string }): JSX.Element {
  const { style, color, ...otherProps } = props
  return (
    <svg
      width={82}
      height={17}
      viewBox={'0 0 82 17'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'block', transition: 'all 0.25s ease-in-out', ...style }}
      {...otherProps}
    >
      <path d='M16.966 12.902h-7.19v4.046h7.19v-4.046z' fill='url(#prefix__paint0_linear)' />
      <path d='M.865 13.93L4.46 7.703l3.496 2.019-3.595 6.226L.865 13.93z' fill='url(#prefix__paint1_linear)' />
      <path d='M11.57 1.577l3.594 6.235-3.496 2.018-3.595-6.226 3.496-2.027z' fill='url(#prefix__paint2_linear)' />
      <path
        d='M2.577 16.939a2.018 2.018 0 100-4.037 2.018 2.018 0 000 4.037zM6.217 10.713a2.018 2.018 0 100-4.036 2.018 2.018 0 000 4.036zM13.353 10.713a2.018 2.018 0 100-4.036 2.018 2.018 0 000 4.036zM16.966 16.939a2.018 2.018 0 100-4.037 2.018 2.018 0 000 4.037zM9.776 16.939a2.018 2.018 0 100-4.037 2.018 2.018 0 000 4.037zM9.776 4.478a2.018 2.018 0 100-4.037 2.018 2.018 0 000 4.037zM30.914 12.055h-5.262l-1.09 3.018H23.03L27.445 2.82h1.676l4.415 12.254h-1.532l-1.09-3.018zm-.523-1.397l-2.126-5.901-2.127 5.901h4.253zM39.293 6.55l-.26 1.297a1.713 1.713 0 00-.929-.279c-1.343 0-1.92 1.523-1.92 3.154v4.352h-1.486V6.343h1.397l.072 1.108c.532-.739 1.207-1.28 2.018-1.28.54 0 .92.208 1.108.379zM44.267 7.568c-1.676 0-3.19 1.324-3.19 3.127 0 1.829 1.523 3.153 3.19 3.153.982 0 1.775-.378 2.505-1.108v1.45c-.676.658-1.604 1.055-2.73 1.055-2.55 0-4.523-2.055-4.523-4.56a4.514 4.514 0 014.523-4.523c1.117 0 2.054.397 2.73 1.055v1.432c-.73-.703-1.523-1.081-2.505-1.081zM56.467 9.974v5.109H54.98v-4.487c0-2.19-.928-3.019-2.28-3.019-2.072 0-2.9 1.811-2.9 3.055v4.45h-1.487V2.136h1.486v5.73c.793-.946 1.92-1.693 3.415-1.693 2.028 0 3.253 1.333 3.253 3.802zM66.351 11.181h-7.244c.054 1.586 1.225 2.74 3.018 2.74 1.433 0 2.298-.775 2.676-1.55l1.316.17c-.343 1.19-1.694 2.713-3.983 2.713-2.64 0-4.505-1.946-4.505-4.586 0-2.451 1.775-4.487 4.361-4.487 2.676 0 4.361 2.153 4.361 4.622v.378zm-7.253-1.072h5.766c-.09-1.36-1.17-2.604-2.883-2.604-1.64 0-2.83 1.171-2.883 2.604zM72.298 6.55l-.261 1.297a1.713 1.713 0 00-.928-.279c-1.343 0-1.92 1.523-1.92 3.154v4.352h-1.486V6.343h1.396l.072 1.108c.532-.739 1.208-1.28 2.019-1.28.54 0 .919.208 1.108.379zM76.938 6.172c1.64 0 2.964.928 3.523 2.153l.072-1.982h1.415v8.73h-1.415l-.072-1.982c-.55 1.226-1.883 2.154-3.523 2.154-2.505 0-4.415-2.036-4.415-4.56 0-2.477 1.92-4.513 4.415-4.513zm.225 7.676c1.793 0 3.136-1.432 3.136-3.153a3.113 3.113 0 00-3.136-3.127 3.14 3.14 0 100 6.28z'
        fill={color ? theme.colors[color] || color : 'currentColor'}
      />
      <defs>
        <linearGradient
          id='prefix__paint0_linear'
          x1={9.803}
          y1={14.924}
          x2={16.071}
          y2={14.924}
          gradientUnits='userSpaceOnUse'
        >
          <stop />
          <stop offset={0.82} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id='prefix__paint1_linear'
          x1={6.192}
          y1={8.752}
          x2={3.058}
          y2={14.18}
          gradientUnits='userSpaceOnUse'
        >
          <stop />
          <stop offset={0.82} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id='prefix__paint2_linear'
          x1={13.388}
          y1={8.786}
          x2={10.254}
          y2={3.358}
          gradientUnits='userSpaceOnUse'
        >
          <stop />
          <stop offset={0.82} stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Logo
