import React from 'react'
import styled from 'styled-components'
import { Link, GatsbyLinkProps } from 'gatsby'
import { Text, TextProps } from '../../../atoms'

export const CategoryLinkElement = styled(Link)<GatsbyLinkProps<any>>`
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    bottom: 0;
    left: 0;
  }
  &::before {
    background-color: ${({ theme }) => theme.colors.dark};
    opacity: 0.24;
  }
  &::after {
    width: 0;
    background-color: ${({ theme }) => theme.colors.purpleBlue};
    transition: width 0.25s ease-in-out;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }

  &.active {
    &::after {
      width: 100%;
    }
  }
`

export const CategoryLinkText: React.FC<TextProps> = styled(Text)<TextProps>`
  ${CategoryLinkElement}.active & {
    color: ${({ theme }) => theme.colors.purpleBlue};
  }

  ${CategoryLinkElement}:hover & {
    color: ${({ theme }) => theme.colors.purpleBlue};
  }
`
