import { ExternalLinkProps, PageInfoLinkProps, PageInfoNavigationLinkProps } from './pages'

export type SubMenuItemGroupColors = 'purpleBlue' | 'purple' | 'lightBlue' | 'peach' | 'yellow' | 'green'

//
// Sub Menu Item Group
//
export interface SubMenuItemGroupProps {
  id: string
  model: {
    apiKey: string
  }
  label: string
  mainColor: SubMenuItemGroupColors
  links: PageInfoNavigationLinkProps[]
}

export function isSubMenuItemGroup(
  subMenuItem: SubMenuItemGroupProps | SubMenuItemGroupSimpleProps
): subMenuItem is SubMenuItemGroupProps {
  return (subMenuItem as SubMenuItemGroupProps).model.apiKey === 'sub_menu_item_group'
}

//
// Sub Menu Item Group Simple
//
export interface SubMenuItemGroupSimpleProps {
  id: string
  model: {
    apiKey: string
  }
  mainColor: SubMenuItemGroupColors
  links: (PageInfoNavigationLinkProps | ExternalLinkProps)[]
}

export function isSubMenuItemSimpleGroup(
  subMenuItem: SubMenuItemGroupProps | SubMenuItemGroupSimpleProps
): subMenuItem is SubMenuItemGroupSimpleProps {
  return (subMenuItem as SubMenuItemGroupSimpleProps).model.apiKey === 'sub_menu_item_group_simple'
}

//
// Sub Menu
//
export interface SubMenuProps {
  id: string
  subMenuContent: (SubMenuItemGroupProps | SubMenuItemGroupSimpleProps)[]
}

export interface MainNavigationItemProps {
  id: string
  label: string
  link: PageInfoLinkProps | ExternalLinkProps
  subMenu: SubMenuProps | null
}
