import styled from 'styled-components'

export const HeaderRoot = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`

export const HeaderElement = styled.header`
  width: 100%;
  height: 8rem;
  background-color: transparent;
  transition: all 0.25s ease-in-out;

  &.header-with-bg {
    &--white {
      background-color: ${({ theme }) => theme.colors.white};
    }

    &--dark {
      background-color: ${({ theme }) => theme.colors.dark};
    }
  }
`
