import React from 'react'
// Utils
import { footerQuery } from './Footer.utils'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Text, Link, SocialButton, Image } from '../../../atoms'
import { FooterLinkText, FooterCreditsText } from './Footer.styled'
import { ReactSVG } from 'react-svg'

interface FooterProps {
  hasPrefooter: boolean
}

const Footer: React.FC<FooterProps> = ({ hasPrefooter }) => {
  const { footer } = footerQuery()

  function afterInjection(err: Error | null, svg: SVGSVGElement | undefined) {
    if (!svg) {
      return
    }
    const animates = [
      ...Array.from(svg.getElementsByTagName('animate')),
      ...Array.from(svg.getElementsByTagName('animateTransform')),
    ]
    const idAnimation = animates.find((a) => !!a.id)?.id
    if (!idAnimation) {
      return
    }
    const newIdAnimation = `${idAnimation}${Math.floor(Math.random() * 1000)}`
    for (const a of animates) {
      if (!a.id) {
        const beginAttr = a.getAttribute('begin')
        if (beginAttr) {
          a.setAttribute('begin', beginAttr.replace(idAnimation, newIdAnimation))
        }
      }
      if (a.id === idAnimation) {
        a.id = newIdAnimation
      }
    }
  }

  return (
    <Box
      as={'footer'}
      pt={hasPrefooter ? [88, 320] : [88, 88, 120]}
      pb={8}
      backgroundColor={'dark'}
      backgroundImage={[
        'radial-gradient(circle at 0% 80%,rgba(139,1,232,0.16),rgba(255,255,255,0) 50%),radial-gradient(circle at 50% -10%,rgba(248,144,144,0.12),rgba(255,255,255,0) 20%),radial-gradient(circle at 100% 40%,rgba(109,216,252,0.12),rgba(255,255,255,0) 48%)',
        'radial-gradient(circle at 0% 80%,rgba(139,1,232,0.16),rgba(255,255,255,0) 20%),radial-gradient(circle at 50% 25%,rgba(248,144,144,0.12),rgba(255,255,255,0) 20%),radial-gradient(circle at 100% 70%,rgba(109,216,252,0.12),rgba(255,255,255,0) 18%)',
      ]}
      overflow={'hidden'}
    >
      <Grid>
        {/* Logo & socials */}
        <Row>
          <Col xs={12} md={4}>
            <Box>
              <ReactSVG
                src={footer.footerLogo.url}
                style={{ width: 190, height: 'auto' }}
                draggable={false}
                afterInjection={afterInjection}
              />
            </Box>
            {footer.socials && (
              <Flex mt={9}>
                {footer.socials.map((s) => (
                  <Box key={s.id} mr={4} flex={'0 0 auto'}>
                    <SocialButton {...s} />
                  </Box>
                ))}
              </Flex>
            )}

            {footer.footerCertificationLogos.length > 0 && (
              <Box mt={9}>
                {!!footer.footerCertificationsLabel && (
                  <Text fontSize={'13px'} color={'white'} opacity={0.8}>
                    {footer.footerCertificationsLabel}
                  </Text>
                )}

                <Flex alignItems={'center'} flexWrap={'wrap'}>
                  {footer.footerCertificationLogos.map((certification, idx) => (
                    <Box
                      flex={['0 0 80px', '0 0 80px', '0 0 auto']}
                      mt={4}
                      mr={idx + 1 < footer.footerCertificationLogos.length ? [4, 4, 5] : undefined}
                    >
                      {certification.customData.url ? (
                        <a href={certification.customData.url} target={'_blank'} rel={'noreferrer noopener'}>
                          <Image
                            alt={certification.alt || ''}
                            image={certification.gatsbyImageData}
                            draggable={false}
                          />
                        </a>
                      ) : (
                        <Image alt={certification.alt || ''} image={certification.gatsbyImageData} draggable={false} />
                      )}
                    </Box>
                  ))}
                </Flex>
              </Box>
            )}
          </Col>
          <Col xs={12} md={8}>
            <Box mt={[10, 10, 0]}>
              <Row end={'md'}>
                {footer.footerNavigation &&
                  footer.footerNavigation.map((n) => (
                    <Col key={n.id} xs={12} sm={6} md={3}>
                      <Box as={'nav'} pb={[10, 10, 0]}>
                        <Text fontSize={4} color={'white'} fontWeight={'semi'}>
                          {n.label}
                        </Text>
                        <Box as={'ul'} mt={5}>
                          {n.items.map((item) => (
                            <Box as={'li'} key={item.id} my={4}>
                              <Link link={item}>
                                <FooterLinkText fontSize={2} color={'white'} opacity={0.8}>
                                  {item.name}
                                </FooterLinkText>
                              </Link>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Col>
                  ))}
              </Row>
            </Box>
          </Col>
        </Row>

        {/* Legals */}
        <Box mt={[0, 70, 120]}>
          <Row>
            <Col xs={12}>
              <Flex justifyContent={'space-between'} alignItems={'center'} flexWrap={['wrap', 'wrap', 'nowrap']}>
                {/* Left part */}
                <Flex
                  flex={['0 0 100%', '0 0 100%', '0 0 auto']}
                  flexDirection={['column-reverse', 'column-reverse', 'row']}
                  alignItems={['stretch', 'stretch', 'center']}
                >
                  {/* Copyright */}
                  <Box flex={'0 0 auto'} mr={[0, 0, 7]} mt={[6, 6, 0]}>
                    <Text fontSize={13} color={'white'} opacity={0.8}>
                      {footer.copyright}
                    </Text>
                  </Box>
                  {/* Legals links */}
                  {footer.legals && (
                    <Flex flex={['0 0 auto', '0 0 auto', '1']} alignItems={'center'} flexWrap={'wrap'}>
                      {footer.legals.map((l) => (
                        <Box key={l.id} mr={7}>
                          <Link link={l}>
                            <FooterLinkText color={'white'} fontSize={13} opacity={0.8}>
                              {l.name}
                            </FooterLinkText>
                          </Link>
                        </Box>
                      ))}
                    </Flex>
                  )}
                </Flex>
                {/* Right part */}
                <Box mt={[4, 4, 0]} flex={['0 0 100%', '0 0 100%', '0 0 auto']}>
                  <Text fontSize={0} color={'white'}>
                    <Text as={'span'} fontSize={'inherit'} color={'inherit'} opacity={0.64}>
                      {'Site Designed by '}
                    </Text>
                    <a href={'https://www.collabwest.com/'} target={'_blank'} rel={'noreferrer noopener'}>
                      <FooterCreditsText
                        as={'span'}
                        fontSize={'inherit'}
                        color={'white'}
                        opacity={0.64}
                        textDecoration={'underline'}
                      >
                        CollabWest
                      </FooterCreditsText>
                    </a>
                    <Text as={'span'} fontSize={'inherit'} color={'inherit'} opacity={0.64}>
                      {' & Developed by '}
                    </Text>
                    <a href={'https://revealstudio.co/'} target={'_blank'} rel={'noreferrer noopener'}>
                      <FooterCreditsText
                        as={'span'}
                        fontSize={'inherit'}
                        color={'white'}
                        opacity={0.64}
                        textDecoration={'underline'}
                      >
                        Reveal
                      </FooterCreditsText>
                    </a>
                  </Text>
                </Box>
              </Flex>
            </Col>
          </Row>
        </Box>
      </Grid>
    </Box>
  )
}
export default Footer
