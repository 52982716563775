import styled from 'styled-components'
import { Flex, FlexProps, Box, BoxProps } from '../../../../../atoms'

export const NavigationItemElement = styled(Flex)<FlexProps>`
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
`

export const NavigationItemInner = styled(Flex)<FlexProps>`
  transition: all 0.25s ease-in-out;

  ${NavigationItemElement}:hover & {
    opacity: 0.8;
  }
`

export const NavigationItemSubMenu = styled(Box)<BoxProps>`
  transform: translate3d(-50%, -10px, 0);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.25s ease-in-out;
  box-shadow: 0 12px 20px rgba(13, 59, 114, 0.06);

  ${NavigationItemElement}:hover & {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translate3d(-50%, 0, 0);
    transition: all 0.5s ease-in-out 0.1s;
  }
`
